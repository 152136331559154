import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

export default function EndResult(props) {
  function displayPortfolioExamples() {
    return _.map(props.endResult.portfolioExamples, (currPortfolio, index) => (
      <Fragment>
        <h5>{currPortfolio.title}</h5>
        <p>{currPortfolio.description}</p>
        <h2
          className="greenAnchor"
          href={`/Portfolio${currPortfolio.anchorLink}`}
        ></h2>
        <a
          className="greenAnchor"
          href={`/Portfolio${currPortfolio.anchorLink}`}
        >
          View Projects
        </a>
      </Fragment>
    ));
  }

  return (
    <Fragment>
      <h3>{props.endResult.title}</h3>
      <p>{props.endResult.description}</p>
      {displayPortfolioExamples()}
      <Link
        className="greenAnchor"
        to={{
          pathname: "/Contact",
          state: { choices: props.choices },
          push: true,
        }}
      >
        Contact us today!
      </Link>
    </Fragment>
  );
}
