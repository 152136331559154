
import {
  faBookMedical,
  faDumbbell,
  faGraduationCap,
  faHandHoldingHeart,
  faHeadset,
  faHospital,
  faHouseCircleCheck,
  faIndustry,
  faLaptopMedical,
  faMoneyBillWave,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";









const constants = {
};

export default constants;
