import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import { NavTop, BrandBar, Industries } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constants from "../utils/constants";
import {
  faBookMedical,
  faDumbbell,
  faGraduationCap,
  faHandHoldingHeart,
  faHeadset,
  faHospital,
  faHouseCircleCheck,
  faIndustry,
  faLaptopMedical,
  faMoneyBillWave,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export default function IndustryDetails() {
  const activeIndustry = useParams();
  const industryList = constants.INDUSTRIES;
  const [selectedIndustry, setSelectedIndustry] = useState(
    constants.INDUSTRIES[activeIndustry.industry]
  );

  // const title = selectedIndustry.title;
  // const description = selectedIndustry.description;
  // const image = industry.image;
  // const card1Icon = industry.card1Icon;
  // const card1Header = industry.card1Header;
  // const card1Description = industry.card1Description;
  // const card2Icon = industry.card2Icon;
  // const card2Header = industry.card2Header;
  // const card2Description = industry.card2Description;
  // const card3Icon = industry.card3Icon;
  // const card3Header = industry.card3Header;
  // const card3Description = industry.card3Description;

  // const title = selectedIndustry.title;
  // const image = selectedIndustry.image;
  //const description = selectedIndustry.description;
  {
    console.log(selectedIndustry);
    console.log(activeIndustry);
  }
  return (
    <div>
      <NavTop />
      <header class="position-relative" style={{ padding: "0px" }}>
        <img
          src={selectedIndustry.banner}
          class="page-header"
          style={{ height: "500px" }}
          loading="lazy"
        ></img>
      </header>
      <section class="py-4">
        <div class="container" style={{ margin: "0", display: "inline-grid" }}>
          <div
            class="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div class="col-lg-12 mt-5">
              <div class="row flex-row-reverse">
                <div class="col-lg-4 justify-content-center d-flex flex-column">
                  <div className="justify-content-center d-flex ">
                    <div style={{ width: "500px" }}>
                      <img
                        src={selectedIndustry.image}
                        alt="img-blur-shadow-blog-2"
                        class="img-fluid border-radius-lg"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-8 pe-lg-5  d-flex flex-column pt-lg-0 pt-3"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignSelf: "center",
                  }}
                >
                  <h1>{selectedIndustry.title}</h1>
                  <p
                    className="useUsCardText"
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    {selectedIndustry.description}
                  </p>
                </div>
              </div>
            </div>
            {/* <Row className="mt-5">
              <div class="col-lg-4 col-md-8">
                <div
                  class="card"
                  style={{ borderColor: "lightgreen", height: "100%" }}
                >
                  <div class="card-body shadow-md">
                    <div
                      className="col-lg-3 col-md-4 col-4 mt-n5 shadow-md "
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "80px",
                      }}
                    >
                      <a style={{ padding: "5px" }}>
                        <FontAwesomeIcon
                          className="avatar avatar-lg "
                          icon={selectedIndustry.card1Icon}
                          style={{
                            color: "#6fb157",
                            height: "2em",
                            paddingTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                    <div class="author">
                      <div class="name">
                        <a className="greenText">
                          {selectedIndustry.card1Header}
                        </a>
                      </div>
                    </div>
                    <p class="mt-3">{selectedIndustry.card1Description}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-8">
                <div class="card" style={{ borderColor: "lightgreen" }}>
                  <div class="card-body shadow-md">
                    <div
                      className="col-lg-3 col-md-4 col-4 mt-n5 shadow-md "
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "80px",
                      }}
                    >
                      <a style={{ padding: "5px" }}>
                        <FontAwesomeIcon
                          className="avatar avatar-lg "
                          icon={selectedIndustry.card2Icon}
                          style={{
                            color: "#6fb157",
                            height: "2em",
                            paddingTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                    <div class="author">
                      <div class="name">
                        <a className="greenText">
                          {selectedIndustry.card2Header}
                        </a>
                      </div>
                    </div>
                    <p class="mt-3">{selectedIndustry.card2Description}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-8">
                <div class="card" style={{ borderColor: "lightgreen" }}>
                  <div class="card-body shadow-md">
                    <div
                      className="col-lg-3 col-md-4 col-4 mt-n5 shadow-md"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "80px",
                      }}
                    >
                      <a style={{ padding: "5px" }}>
                        <FontAwesomeIcon
                          className="avatar avatar-lg "
                          icon={selectedIndustry.card3Icon}
                          style={{
                            color: "#6fb157",
                            height: "2em",
                            paddingTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                    <div class="author">
                      <div class="name">
                        <a className="greenText">
                          {selectedIndustry.card3Header}
                        </a>
                      </div>
                    </div>
                    <p class="mt-3">{selectedIndustry.card3Description}</p>
                  </div>
                </div>
              </div>
            </Row> */}
          </div>
        </div>
      </section>
      <a href="/contactus">
        <button type="submit" class="btn btn-primary bg-gradient-primary mb-0">
          Contact Us
        </button>
      </a>
    </div>
  );
}
