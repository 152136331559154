import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Homepage from "./HomePage";

function NavTop(args) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ display: "block", justifyContent: "center" }}>
      <Navbar container="fluid" className="glass" expand="md">
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/home">
                <a href="/home" data-content="Home">
                  Home
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/aboutus">
                <a href="/aboutus" data-content="About">
                  About
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/whyuseus">
                <a href="/whyuseus" data-content="Services">
                  Services
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/portfolio">
                <a href="/portfolio" data-content="Portfolio">
                  Portfolio
                </a>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#">
                <a href="#" data-content="Empower">
                  Empower
                </a>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="/contactus">
                <a href="/contactus" data-content="Contact">
                  Contact
                </a>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavTop;
