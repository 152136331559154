import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import constants from "../utils/constants";
import EndResult from "./EndResult";
import { Button, Form, FormGroup, Input, Label, ModalFooter } from "reactstrap";
import _ from "lodash";
import { NavTop } from ".";

export default function Questions() {
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [currentUserChoiceId, setCurrentUserChoiceId] = useState(null);
  const [choices, setChoices] = useState([]);
  const [endResultId, setEndResultId] = useState(0);

  function userChoiceMade(currOption) {
    let currentChoices = choices;
    currentChoices.push({
      questionId: currentQuestionId,
      choiceId: currentUserChoiceId,
    });
    setChoices(currentChoices);
    nextQuestion();
  }

  function nextQuestion() {
    let userChoice = _.find(choices, (ele) => {
      return ele.questionId == currentQuestionId;
    }); // used to find the choice that the user has made
    let choice =
      constants.QUESTIONS[currentQuestionId].options[userChoice.choiceId];
    if (choice.endResult) {
      setEndResultId(choice.endResult);
    }
    setCurrentUserChoiceId(null);
    setCurrentQuestionId(choice.nextId);
  }

  // used to display radio button options for current text node
  function displayOptions() {
    return _.map(
      constants.QUESTIONS[currentQuestionId]?.options,
      (currOption, index) => (
        <FormGroup
          className="modal-radios"
          check
          key={`${currentQuestionId}-${index}`}
        >
          <Label>
            <Input
              type="radio"
              name={`QUESTION_${currentQuestionId}`}
              value={currOption.text}
              onChange={() => {
                setCurrentUserChoiceId(currOption.id);
              }}
            />
            {currOption.text}
          </Label>
        </FormGroup>
      )
    );
  }
  return (
    <div className="App">
      <NavTop />
      <div class="page-header">
        <div
          style={{ zIndex: 0 }}
          class="position-absolute fixed-bottom ms-auto w-50 h-100 rounded-3 d-none d-sm-none d-md-block me-n4 surveyBackground"
        ></div>
        <div class=" py-5">
          <div class="row">
            <div class="col-lg-7 d-flex justify-content-center flex-column">
              <div class="card card-body d-flex z-index-1 justify-content-center shadow-lg p-5 blur align-items-center">
                <h3 class="text-center">Contact us</h3>
                <Fragment>
                  {currentQuestionId == 8 ? (
                    <EndResult
                      endResult={constants.END_RESULTS[endResultId]}
                      choices={choices}
                    />
                  ) : (
                    <div>
                      <Row>
                        <h4>{constants.QUESTIONS[currentQuestionId]?.text}</h4>
                      </Row>
                      <Row
                        style={{
                          textAlign: "initial",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs="8">{displayOptions()}</Col>
                      </Row>
                      <Button
                        onClick={userChoiceMade}
                        disabled={currentUserChoiceId === null}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
