import { React } from "react";
import { Col, Nav, Row, Card } from "reactstrap";
import { NavTop } from ".";
import Startup from "../assets/images/buisness/Startup.jpg";
import POC from "../assets/images/buisness/POC.jpg";
import Upgrade from "../assets/images/buisness/Upgrade.jpg";
import Emergency from "../assets/images/buisness/Emergency.jpg";

const Section = ({ index, image, text }) => (
  <div>
    <div className="container">
      <div className="text-container">
        <p>{text}</p>
      </div>
      <div className="image-container">
        <img src={Startup} alt={`Image ${index + 1}`} />
      </div>
    </div>
  </div>
);

export default function WhyUseUs() {
  return (
    <div className="App">
      <NavTop />
      <div style={{ backgroundColor: "#faf9f6", paddingTop: "30px" }}>
        <Card className="useUsCard">
          <Row>
            <Col xs="12" md="6">
              <img className="image-container" src={Startup} />
            </Col>
            <Col
              xs="12"
              md="6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Row>
                <Col className="postionHeader" xs="12">
                  <a>
                    <p className="useUsCardHeader">STARTUPS</p>
                  </a>
                </Col>

                <Col style={{ textAlign: "left" }}>
                  <p className="useUsCardText">
                    The determination, hard work and excitement inherent to
                    successful startups are a kindred culture to our own and we
                    have had the pleasure of exceeding both our customer and
                    their markets' expectations. We thrive in this fast-paced,
                    must-deliver context where few others can.
                  </p>
                </Col>
              </Row>
            </Col>{" "}
          </Row>
        </Card>
        <Card className="useUsCard">
          <Row>
            <Col xs="12" md="6">
              <img className="image-container" src={Emergency} />
            </Col>
            <Col
              xs="12"
              md="6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Row>
                <Col className="postionHeader" xs="12">
                  <a>
                    <p className="useUsCardHeader">EMERGENCIES </p>
                  </a>
                </Col>
                <Col style={{ textAlign: "left" }}>
                  <p className="useUsCardText">
                    The heavy reliance of business on technology unfortunately
                    makes modern business susceptible to technology-related
                    risks and limitations. We have experience being called in to
                    assist with the "impossible" when things look bleak, and we
                    have routinely beaten improbable odds in order to come to a
                    great resolution.
                  </p>
                </Col>
              </Row>
            </Col>{" "}
          </Row>
        </Card>
        <Card className="useUsCard">
          <Row>
            <Col xs="12" md="6">
              <img className="image-container" src={Upgrade} />
            </Col>
            <Col
              xs="12"
              md="6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Row>
                <Col className="postionHeader" xs="12">
                  <a>
                    <p className="useUsCardHeader">OBSOLETE TECH REVAMPS</p>
                  </a>
                </Col>
                <Col style={{ textAlign: "left" }}>
                  <p className="useUsCardText">
                    With the pace of modern technical innovations it has become
                    very easy to fall behind the leading edge. It's good
                    business sense to maximize ROI on technical investments and
                    to wait as long as possible to implement the improved
                    innovations of tomorrow, but delaying can easily become a
                    liability when your business platform falls out of support
                    by your technology providers, innovations beyond your
                    control that render your platform incompatible are foisted
                    upon you, or simply when technology professionals able to
                    work on your platform become sparse. We already have a
                    motley assortment of skills from yesteryear and do not
                    hesitate to learn aged technologies in order to reverse
                    engineer a path to the future.
                  </p>
                </Col>
              </Row>
            </Col>{" "}
          </Row>
        </Card>
        <Card className="useUsCard">
          <Row>
            <Col xs="12" md="6">
              <img className="image-container" src={POC} />
            </Col>
            <Col
              xs="12"
              md="6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Row>
                <Col className="postionHeader" xs="12">
                  <a>
                    <p className="useUsCardHeader">PROOFS OF CONCEPT</p>
                  </a>
                </Col>
                <Col style={{ textAlign: "left" }}>
                  <p className="useUsCardText">
                    With the modern focus on profits today, often at the expense
                    of tomorrow, there are not many companies that still find
                    means to invest sufficiently in R&D. What was once an entire
                    department in many organizations has gone the way of the
                    dinosaur. We are able to take a technology-related idea that
                    you're just not sure is possible and discover the way
                    forward.
                  </p>
                </Col>
              </Row>
            </Col>{" "}
          </Row>
        </Card>
        <Card className="useUsCard"></Card>
      </div>
    </div>
  );
}
