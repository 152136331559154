import { React, useEffect, useState } from "react";
import { CiMail } from "react-icons/ci";
import { NavTop, BrandBar, Industries, ContactUs } from ".";
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import JBDesignsLogo from "../assets/images/logos/JBDesignsLogo2.png";
import Jesse_Sightsource from "../assets/images/headshots/DarkJesse.jpg";
import LofiScreenshot from "../assets/images/headshots/LofiScreenshot.PNG"
import Stopwatch from './Stopwatch';
export default function Homepage() {
  const heightOutput = document.querySelector("#height");
  const widthOutput = document.querySelector("#width");
  const [menuHasOpened, setMenuHasOpened] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isXRotation, setIsXRotation] = useState(false);
  const [modal, setModal] = useState(false);
  const [jsModal, setJsModal] = useState(false);
  const [flutterModal, setFlutterModal] = useState(false);
  const [cSharpModal, setCSharpModal] = useState(false);
  const [sqlModal, setSqlModal] = useState(false);

  // Function to toggle modal visibility
  const toggleJsModal = () => setJsModal(!jsModal);
  const toggleFlutterModal = () => setFlutterModal(!flutterModal);
  const toggleCSharpModal = () => setCSharpModal(!cSharpModal);
  const toggleSqlModal = () => setSqlModal(!sqlModal);

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };
  const menuOpened = () => {
    setMenuHasOpened(true);
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const bars = document.querySelectorAll('.bar');

    const random = (min, max) => Math.random() * (max - min) + min;
    
    const setRandomHeights = () => {
      // bars is a nodelist
      [...bars].forEach(bar => bar.style.height = random(0, 500) + 'px');
    }
    
    setInterval(setRandomHeights, 3000);
  }, []);

  const toggleRotation = () => {
    setIsXRotation((prevState) => !prevState);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div>
      <article className="scroller">
      {/* <article className="scroller"> */}
        <div className="blankBg" style={{ zIndex: "-2", height: "100%" }}>
          <section style={{ position: "relative", height: "52em" }}>
            <Row className="bg-gradient-primary" style={{height: "52em", justifyContent:"center", padding:"0px", margin:"0px" }}>
             {!isSmallScreen ?<Row style={{height:"0"}}>
                <Col xs={8}>
                  <img
                    className="logoTopLeft"
                    src={JBDesignsLogo}
                  />
                </Col>
              <Col xs={4}
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    float: "right",
                    paddingRight: "40px",
                    position: "inherit",
                    zIndex: 20,
                  }}
                >
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="rotate-button"
                    onClick={() => {
                      toggleRotation();
                      menuOpened(true);
                    }}
                  >
                    <a class="#" href="#open-contact"><button className="myButton3">Get In Touch!</button></a>
                      <div id="open-contact" class="contact-window">
                    <div style={{zIndex:"100"}}>
                      <a href="#" title="Close" class="modal-close">Close</a>
                      <h1 className="blueText">Contact</h1>
                      <div className=""> <ContactUs/></div>
                    </div>
                    </div>
                  </button>
                </div>
              </Col>
              </Row> : null } 
            <Row style={{padding:0}}>
              <Col style={{padding:0,margin:0}} xs={12} md={7} lg={6}>
              <div className="blueBackground" >
                {isSmallScreen ? <svg
                  style={{ marginTop: "40px" }}
                  className="designMatters2 "
                  //viewBox="350 10 1600 300"
                  viewBox="0 0 1000 400"
                >
                  <symbol id="s-text">
                    <text text-anchor="middle" x="50%" y="43%">
                      Design
                    </text>
                    <text text-anchor="middle" x="50%" y="98%">
                      Matters.
                    </text>
                  </symbol>
                  <g class="g-ants">
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                  </g>
                </svg> : <svg
                  style={{ paddingTop: "5vw" }}
                  className="designMatters "
                  //viewBox="350 10 1600 300"
                  viewBox="0 0 1200 300"
                >
                  <symbol id="s-text">
                    <text text-anchor="middle" x="24%" y="43%">
                      Design
                    </text>
                    <text text-anchor="middle" x="27%" y="98%">
                      Matters.
                    </text>
                  </symbol>
                  <g class="g-ants">
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                    <use href="#s-text" class="text-copy"></use>
                  </g>
                </svg>}
                
                <Row className="blueBackground" style={{ display: "flex", justifyContent: "start", padding:"0px", margin:"0px" }}>
                  <h3 className="designMattersSubtext"
                    style={{paddingTop:"25px"
                    }}
                  >
                    Where Innovation Meets Aesthetics:
                  </h3>
                  <h3 style={{paddingTop:"10px"}} className="designMattersSubtext"
                  >                    
                    Showcasing The Importance of Harmonizing Design with Functionality.
                  </h3>
                  {isSmallScreen ? <div> <a class="#" href="#open-contact"><button className="myButton2">Get In Touch!</button></a>
                      <div id="open-contact" class="contact-window2">
                    <div style={{zIndex:"100"}}>
                      <a href="#" title="Close" class="modal-close">Close</a>
                      <h1 className="blueText">Contact</h1>
                      <div className=""> <ContactUs/></div>
                    </div>
                    </div></div> : null}
                 
                </Row>
              </div>
              </Col>
            <Col style={{display:"inline-grid", justifyItems:"center"}} xs={12} md={4} lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 0 }}> 
              <img className="headShot" src={Jesse_Sightsource}></img>
            </Col>
            </Row>
            </Row>
          </section>
          {/* Page 2 */}
          <section className="bg-gradient-secondary">
            <Row
              style={{
                padding:"0px", margin:"0px"
              }}
            >
              <Col className="expertiseText"
                style={{
                  textAlign:"start",
                  color:"white"
                }}
                xs={12}
                md={12}
                lg={12}
                xl={5}
              >
                <h2 className="headers3">Expertise</h2>
                {isSmallScreen ? 
                <div>
                <Col xs={12}
                  sm= {{ size: 9, offset: 1 }}
                  md={6}
                  lg={5}
                  xl={6}>
                    <Stopwatch />
                </Col>
                
                <Row className="mobileExpertiseBoxes">
        <Col xs={6}>
          <Button className="myButton" onClick={toggleJsModal}>JS</Button>
          <Modal isOpen={jsModal} toggle={toggleJsModal}>
            <ModalHeader toggle={toggleJsModal}>JavaScript</ModalHeader>
            <ModalBody>
              <p className="blueText">
                I have extensive experience with React in a wide range of projects.
                I have built full apps from scratch as well as integrated my code
                into a variety of existing projects.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleJsModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </Col>

        <Col xs={6}>
          <Button className="myButton" onClick={toggleFlutterModal}>Flutter</Button>
          <Modal isOpen={flutterModal} toggle={toggleFlutterModal}>
            <ModalHeader toggle={toggleFlutterModal}>Flutter</ModalHeader>
            <ModalBody>
              <p className="blueText">
                Was part of a two-person team who successfully created and deployed
                a group fitness app for our client. I have also converted part of
                a TI-89 calculator app written in C into Flutter.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleFlutterModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>

      <Row className="" style={{ textAlign: "center", marginTop: "2em" }}>
        <Col xs={6}>
          <Button className="myButton" onClick={toggleCSharpModal}>C#</Button>
          <Modal isOpen={cSharpModal} toggle={toggleCSharpModal}>
            <ModalHeader toggle={toggleCSharpModal}>C#</ModalHeader>
            <ModalBody>
              <p className="blueText">
                I have practical experience working with databases, utilizing technologies
                such as Microsoft SQL Server. I am adept at designing and optimizing
                database schemas, writing efficient queries, and ensuring data integrity.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleCSharpModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </Col>

        <Col xs={6}>
          <Button className="myButton" onClick={toggleSqlModal}>SQL</Button>
          <Modal isOpen={sqlModal} toggle={toggleSqlModal}>
            <ModalHeader toggle={toggleSqlModal}>SQL</ModalHeader>
            <ModalBody>
              <p className="blueText">
                I have practical experience working with databases, utilizing technologies
                such as Microsoft SQL Server. I am adept at designing and optimizing
                database schemas, writing efficient queries, and ensuring data integrity.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleSqlModal}>Close</Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
                </div> 
                : 
                <Row >
                  <Col md={6} lg={6} xl={12}>
                    <h3 style={{   font:  "Montserrat" }}>Javascript</h3>
                    <p style={{ fontSize:18,  font:  "Montserrat" }}>
                      I have extensive experience with React in a wide range of projects. I have built full apps from scratch as well as integrated my code into a variety of exisitng projects.
                    </p>
                  </Col>
                  <Col md={6} lg={6} xl={12}>
                    <h3 style={{  font:  "Montserrat" }}>Flutter</h3>
                    <p style={{ fontSize:18,  font:  "Montserrat" }}>
                      Was part of a two person team who successfully created and deployed a group fitness app for our client. I have also converted part of a TI-89 calculator app written in C into Flutter.
                    </p>
                  </Col>
                  <Col md={6} lg={6} xl={12}>
                    <h3 style={{ font:  "Montserrat" }}>C#</h3>
                    <p style={{ fontSize:18,  font:  "Montserrat" }}>
                      I have practical experience working with databases, utilizing technologies such as Microsoft SQL Server. I am adept at designing and optimizing database schemas, writing efficient queries, and ensuring data integrity.</p>
                  </Col>
                  <Col md={6} lg={6} xl={12}>  
                    <h3 style={{font:  "Montserrat" }}>SQL</h3>
                    <p style={{ fontSize:18,  font:  "Montserrat" }}>
                      I have practical experience working with databases, utilizing technologies such as Microsoft SQL Server. I am adept at designing and optimizing database schemas, writing efficient queries, and ensuring data integrity.</p>
                  </Col>
                </Row>}
              </Col>
              <Col
                className="jesvg"
                xs={1}
                sm={6}
                md={6}
                lg={5}
                xl={7}
              >
               {isSmallScreen ? null : <Stopwatch viewBox="" /> } 
              </Col>
            </Row>
          </section>
          <section className="bg-gradient-third">
            <Row
              style={{
                padding:"0px", margin:"0px",
              }}
            >
              <Col className="lofiText" style={{display:"block"}}
               xs={12}
               sm={12}
               md={12}
               lg={12}
               xl={4}
              >
                <h2 className="headers2">Lofi Wanderer</h2>
                <p className="lofiParagraph" style={{ fontSize: 18,  font:  "Montserrat" }}>
                  During Covid, while trying to find ways to cope with the social seperation, two new interests that formed for me were Lofi Music and POV Walking videos. 
                  <br/><br/>Now, I always have lofi playing and a walking video playing on an extra monitor so that I can look over for a quick mental break. suddenly be in a yokocho alley in Japan, or hiking the side of a mountain in Switzerland.
                  <br/><br/> I created this project to combine these two interests into a fun little web app!  
                </p>
              </Col>
              <Col style={{display:"grid"}} className="lofiVideo"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={8}
              >
              {isSmallScreen ? 
              <a className="videoAnchor" target="_blank" href="https://lofiwanderer.com/"><img style={{width:"100%"}} src={LofiScreenshot}></img></a>  
              :<div> <a className="videoAnchor" target="_blank" href="https://lofiwanderer.com/"> <img style={{width:"80%"}} src={LofiScreenshot}></img></a></div>}
              </Col>
            </Row>
          </section>
        </div>
      </article>
    </div>
  );
}
