// import React, { useState } from "react";
import "./assets/index.scss";
import "./App.css";

import {
  WhyUseUs,
  HomePage,
  AboutUs,
  ContactUs,
  Portfolio,
  Survey,
  Questions,
  IndustryDetails,
} from "./components";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/whyuseus" element={<WhyUseUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/survey" element={<Survey />} />
        <Route
          key={"/industrydetails"}
          path={`${"/industrydetails/:industry"}`}
          element={<IndustryDetails />}
        />
        ,
      </Routes>
    </Router>
  );
}

export default App;
